import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alphabetLinks", "listElements", "searchInput"];

  groups = {};

  searchInputTimeout = null;

  handleSearchInput() {
    if (this.searchInputTimeout) {
      clearTimeout(this.searchInputTimeout);
    }

    this.searchInputTimeout = setTimeout(() => {
      this.updateElementVisibilityByQueryInput();
    }, 500);
  }

  updateElementVisibilityByQueryInput() {
    const query = this.searchInputTarget.value.toLowerCase();

    const checkboxes =
      this.listElementsTarget.querySelectorAll(".sorted-checkbox");

    checkboxes.forEach((checkbox) => {
      const labelElem = checkbox.querySelector("label");
      const label = labelElem ? labelElem.innerText.toLowerCase() : "";

      const labelMatchesQuery = label.includes(query);
      checkbox.setAttribute(
        "style",
        `display: ${labelMatchesQuery ? "" : "none"};`
      );
    });

    Object.keys(this.groups).forEach((letter) => {
      const group = this.groups[letter];
      const matchingElements = group.elements || [];
      const groupContainsVisibleChildren = matchingElements.some(
        (x) => x.style.display !== "none"
      );

      group.domElement.style.display = groupContainsVisibleChildren
        ? ""
        : "none";
    });
  }
}
