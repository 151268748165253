import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    behavior: { type: String, default: "smooth" },
    container: { type: String, default: "" },
  };

  scrollToSection(event) {
    event.preventDefault();

    const targetKey = event.target.dataset.scrollTarget;
    if (!targetKey) return;

    const container = this.containerValue
      ? document.querySelector(this.containerValue)
      : this.element.parentElement;

    if (!container) {
      return;
    }

    const section = container.querySelector(`[data-scroll-key="${targetKey}"]`);
    if (section) {
      section.scrollIntoView({ behavior: this.behaviorValue });
    }
  }
}
